// import normalize from 'normalize.css'
import React from 'react'
import '../components/index.css'
import config from '../../config'
// import {Link} from 'gatsby'
import { Helmet } from 'react-helmet'
import favicon from '../../static/assets/favicon.ico'
import { P2V } from '../components/p2v'

const Layout = ({ pageContext: { base, xml } }) => {
  return (
    <div
      css={{
        position: 'fixed',
        overflow: 'hidden',
        width: '100%',
        height: '100%'
      }}
      className="bodyLayout"
    >
      <Helmet>
        <link rel="icon" href={favicon} />
        <title>{config.site.title}</title>
      </Helmet>
      <P2V {...{ base, xml, onClick: (_) => {} }} />
    </div>
  )
}

export default Layout
