import React from 'react'
export class P2V extends React.Component {
  componentDidMount() {
    const xml = this.props.xml || 'out.xml'
    const iframe = document.createElement('iframe')
    iframe.style= "height:100%;width:100%"
    iframe.onload = this.props.onLoad
    const html = `
    <!DOCTYPE html>
    <base href="${this.props.base}">
    <script src="/assets/pano2vr_player.js">
    </script>
    <body style="margin:0">
    <div style="left:40px;top:40px;width:5em;position:absolute;font-size:20px;color:white;opacity:0.8;z-index:10">
	PANORAMA
    </div>
    <div id="container" style="cursor:grab;width:100%;height:100%;position:absolute">
    This content requires HTML5/CSS3, WebGL.
    </div>
    <script>
    if (ggHasHtml5Css3D() || ggHasWebGL()) {
      pano=new pano2vrPlayer("container");
      pano.readConfigUrl("${xml}");
      if(typeof hideUrlBar !="undefined") setTimeout(function() { hideUrlBar(); }, 10);
    }
    </script>
    <noscript>
      <p><b>Please enable Javascript!</b></p>
    </noscript>
    </body>
    `
    this.body.appendChild(iframe)
    iframe.contentWindow.document.open()
    iframe.contentWindow.document.write(html)
    iframe.contentWindow.document.close()
  }
  render() {
    return <div ref={ref => (this.body = ref)} style={{height:'100%',width:'100%'}} />
  }
}
